"use strict";
const fogSideBarListInit = () => {
    const sidebarFogRoot = document.querySelector('.fogTopRooms');
    if (!sidebarFogRoot) {
        return false;
    }
    const list = Array.from(sidebarFogRoot.querySelectorAll('.fogTopRooms__list'));
    const tabs = Array.from(sidebarFogRoot.querySelectorAll('nav a'));
    const activateList = (data) => {
        list.forEach((item) => {
            if (item.dataset.type === data.substring(1)) {
                (item).style.display = 'block';
            }
            else {
                (item).style.display = 'none';
            }
        });
    };
    const activateTab = (data) => {
        tabs.forEach((item) => {
            if ((item).dataset.type === data) {
                item.classList.add('active');
                const footerLink = sidebarFogRoot.querySelector('.fogTopRooms footer a');
                if (footerLink) {
                    footerLink.hash = data;
                }
                activateList(data);
            }
            else {
                item.classList.remove('active');
            }
        });
    };
    const fogTabsListener = () => {
        function handleTabClick() {
            const { type } = this.dataset;
            if (type) {
                activateTab(type);
            }
        }
        tabs.forEach((item) => {
            item.addEventListener('click', handleTabClick);
        });
    };
    const isEmptyTab = (data) => !list.some((item) => (item).dataset.type === data);
    const disableEmptyTab = () => {
        tabs.forEach((item) => {
            const type = (item).dataset.type;
            if (type && isEmptyTab(type.substring(1))) {
                item.classList.add('disabled');
            }
        });
    };
    disableEmptyTab();
    fogTabsListener();
    return true;
};
fogSideBarListInit();
document.addEventListener('turbo:load', () => {
    fogSideBarListInit();
});
